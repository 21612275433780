import React, { Component } from 'react';
import { ResponsiveContainer, LineChart, PieChart, Pie, Sector, Cell, Legend, Tooltip, Text, BarChart, Bar } from 'recharts';

import Button from 'antd/lib/button';
import Progress from 'antd/lib/progress';
import Card from 'antd/lib/card';
import Core from "../../Core.js";

import './index.scss';

export default class LivePollResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
          loaded: false,
          question: "",
          data: [],
        };

        this.getActiveLivePoll = this.getActiveLivePoll.bind(this);

    }

    componentDidMount() {
      this.getActiveLivePoll();
    }

    getActiveLivePoll() {
        const delay = 2000;
        const fail = () => {
            this.setState({ data: { options: [] } });
            window.setTimeout(() => this.getActiveLivePoll(), delay);
        };
        Core.apiService(`live-poll/live-stats`, {
            method: 'GET',
            onSuccess: (data) => {
                if (typeof data == 'undefined') {
                    this.setState({ data: { options: [] } });
                } else if (data.json) {
                  const totalVotes = data.json.total;

                  let graphData = [];

                  if (Array.isArray(data.json['options'])) {
                      data.json['options'].forEach(function (option) {
                          graphData.push({
                              name: option.description,
                              value: option.total,
                              percent: option.percentage
                          });
                      });
                  }

                  this.setState({
                      loaded: true,
                      data: graphData,
                      question: data.json['question'] || ""
                  });
                  window.setTimeout(() => this.getActiveLivePoll(), delay);
                } else {
                    console.log("live-stats - Failed: JSON ERROR");
                    fail();
                }
            },
            onFail: (data) => {
              console.log("live-stats - Failed: ", data);
              fail();
            }
        });
    }

    renderCustomizedLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, percent, index,
    }) => {
        console.log(percent)
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        if (percent > 0) {
            return (
                <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {`${(percent * 100).toFixed(0)}%`}
                </text>
            );
        } else {
            return null;
        }
    };


    render() {
      const { data, question } = this.state;
      const progressChart = data.map((item, index) => (
          <>
              <Progress percent={item.percent} strokeColor={"#202859"} trailColor={"#b1b0b0"} showInfo={false}/>
              <span>{item.name + ": " + item.percent}</span>
          </>
      ));

      const containerHeight = (window.innerHeight - 222 ) + 25;

      return (
        <div className="graph-container" style={{minHeight: `${containerHeight}`}}>

          <ResponsiveContainer height={containerHeight} width="100%">

              <div className="livepoll-question">
                {/*<div className="button-wrapper">
                  <Button type="primary" onClick={this.getActiveLivePoll}>
                      Refresh
                  </Button>
                </div>*/}
                  {data.length > 0 ? (
                      <Card title={""} bordered={true} style={{ minWidth: "800px", maxWidth: "800px" }} className="livepoll-card">
                          <h1>{question}</h1>
                          {progressChart}
                      </Card>
                  ) : null}
              </div>
          </ResponsiveContainer>
        </div>
        );
    }
}
