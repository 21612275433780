
import React, { Component } from 'react';
import { Layout, Button, Row, Col } from 'antd';
import styles from './Cookies.scss';
import Core from '../Core';
import {
  Link
} from "react-router-dom";
import MainFooter from "../containers/Footer";

class Cookies extends Component {

  constructor(props) {
    super(props);
    this.state = {
      //accepted: sessionStorage.getItem('cookies-accepted')
    };
    //this.onClick = this.onClick.bind(this);
  }

  /* onClick() {
    sessionStorage.setItem('cookies-accepted', '1');
    this.setState({ accepted: '1' });
  } */

  componentDidMount() {
    if(document.getElementsByClassName("cookies--popup").length > 0)
      this.props.setCookiesHeight(document.getElementsByClassName("cookies--popup")[0].offsetHeight);
  }

  render() {
    if (this.props.accepted === '1') {
      return null;
    }
    return (
      <div className="cookies">
        <div className="cookies--popup">
          <MainFooter />
          <Row align="middle" className="cookies--popup__content">
            <Col>
              <p className="cookies--popup__text">
                O site usa cookies para personalizar o conteúdo, guardar as suas preferências e monitorizar a performance do site. Ao clicar em "Aceito", aceita a utilização destes cookies.
              </p>
            </Col>
            <Col className="cookies--popup__button">
              <a href="/politica-de-cookies" target="_blank">Saber Mais</a>
              <Button type="primary" shape="round" onClick={this.props.onClick}>
                Aceito
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Cookies;
